import React, { useState, useEffect } from 'react'
import { listofImages } from '../../utils.js'

function CarsouselComponent() {
  const [listOfImages, setListOfImages] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [carouselCount, setCarouselCount] = useState(0)

  useEffect(() => {
    setListOfImages(listofImages)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    setInterval(() => {
      document.getElementById('carouselNext').click()
    }, 2500)
  }, [])

  return (
    <>
      <div className="flex-row pt-6 md:pt-0 mr-12 ml-12">
        <div id="carouselExampleCrossfade" className="carousel slide carousel-fade relative" data-bs-ride="carousel">
          <div className="carousel-inner relative w-full overflow-hidden">
            <div className="carousel-item active float-left w-full">
              <img src={`/art/mochi_server_webhook_1.png`} className="block w-full rounded-lg" style={{ height: '100%' }} />
            </div>

            {isLoading ? null : (
              <>
                {listOfImages.map((d) => (
                  <div className="carousel-item float-left w-full">
                    <img src={`${d}`} className="block w-full rounded-lg shadow" style={{ height: '100%' }} />
                  </div>
                ))}
              </>
            )}
          </div>
          <button
            className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target="#carouselExampleCrossfade"
            data-bs-slide="prev"
            id="carouselPrev"
          >
            <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target="#carouselExampleCrossfade"
            data-bs-slide="next"
            id={'carouselNext'}
          >
            <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default CarsouselComponent
