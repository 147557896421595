import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useAccount, useContractRead, useContractWrite, usePrepareContractWrite, useProvider, useWaitForTransaction } from 'wagmi'
import contractInterface from '../../mintingABI.json'
import { parse } from 'papaparse'
import { useForm } from 'react-hook-form'
import { ethers } from 'ethers'
import { IsJsonString } from '../../utils'
import FlipCard, { BackCard, FrontCard } from './flip-card.component'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { wallet } from '@rainbow-me/rainbowkit'

const MINT_PRICE_ETH = '100'
const CONTRACT_ADDRESS = '0x9a513b96333929b6240f605bb02dc909c13b1e67'

export interface WhitelistData {
  wallet: string
  whitelistQuantity: string
  spotInWhitelist: string
  proof: string
}

export interface WalletData {
  connected: boolean
  walletAddress: string
  chainId: number
}

const contractConfig = {
  addressOrName: CONTRACT_ADDRESS,
  contractInterface: contractInterface,
}

const RainbowKitMint = memo(() => {
  const [totalMintSupply, setTotalMintSupply] = React.useState(0)
  const [totalMinted, setTotalMinted] = React.useState(0)
  const { isConnected, address } = useAccount()

  const [walletData, setWalletData] = useState<WalletData>()

  const [status, setStatus] = useState<string>('')
  const [statusType, setStatusType] = useState<string>('')

  const { data: totalSupplyData } = useContractRead({
    ...contractConfig,
    functionName: 'totalSupply',
    watch: true,
  })

  const { data: maxSupplyData } = useContractRead({
    ...contractConfig,
    functionName: 'MAX_SUPPLY',
    watch: true,
  })

  const { data: totalMintsFromWallet } = useContractRead({
    ...contractConfig,
    functionName: 'balanceOf',
    watch: true,
    args: [walletData?.walletAddress],
  })

  /* Set total NFTs Minted by this wallet */
  useEffect(() => {
    if (totalMintsFromWallet) {
      setTotalMinted(totalMintsFromWallet.toNumber())
    }
  }, [totalMintsFromWallet])

  /* Set totalSupply of NFTs minted so far for this contract */
  useEffect(() => {
    if (totalSupplyData) {
      setTotalMintSupply(totalSupplyData.toNumber())
    }
  }, [totalSupplyData])

  /* Set the users wallet data state object */
  useEffect(() => {
    setWalletData({
      connected: true,
      walletAddress: `${address}`,
      chainId: 40,
    })
  }, [address])

  /* Prepare write (mint) function on this contract */
  const { config: contractWriteConfig, error: prepareContractError } = usePrepareContractWrite({
    ...contractConfig,
    functionName: 'mint',
    args: [],
    overrides: {
      value: ethers.utils.parseEther(MINT_PRICE_ETH).mul(1),
    },
  })

  /* Setup mint function ready to be fired off when buttton is pushed */
  const {
    data: mintData,
    write: mint,
    isLoading: isMintLoading,
    isSuccess: isMintStarted,
    error: mintError,
  } = useContractWrite(contractWriteConfig)

  /* Setup mint transaction response data */
  const {
    data: txData,
    isSuccess: txSuccess,
    error: txError,
  } = useWaitForTransaction({
    hash: mintData?.hash,
  })

  /* onClick event for form button, initiates transaction approval window */
  const whitelistMint = async () => {
    try {
      if (!address) {
        enqueueSnackbar('Error, you must connect to a wallet first.', { variant: 'error' })
        return
      }

      setStatus(`Minting NFT...`)
      const mintVal = mint?.()
    } catch (error: any) {
      enqueueSnackbar(error?.reason, { variant: 'error' })
    }
  }

  /* Handle any errors that we might receive from the contract when preparing the write function */
  useEffect(() => {
    if (prepareContractError?.message) {
      const msg = prepareContractError?.message.substring(prepareContractError?.message.indexOf('error={"code"') + 6).split(', code=')[0]
      if (IsJsonString(msg)) {
        const transactionResponse = JSON.parse(msg)
        const err = transactionResponse?.message.replace('execution reverted:', 'Error:')
        setStatus(err)
        enqueueSnackbar(err, { variant: 'error' })
        setStatusType('error')
      }
    }
  }, [prepareContractError])

  /* Update status message when txSuccess boolean changes */
  useEffect(() => {
    if (txSuccess) {
      setStatus('Mint NFT')
      enqueueSnackbar('Your NFT(s) have been successfully minted.', { variant: 'success' })
      setStatusType('success')
    }
  }, [txSuccess])

  /* Show snackbar error & update statusMessage & type states if/when txError changes. */
  useEffect(() => {
    if (txError?.message) {
      setStatus(txError.message)
      setStatusType('error')
      enqueueSnackbar(txError.message, { variant: 'error' })
    }
  }, [txError])

  /* Show snackbar error & update statusMessage & type states if/when mintError changes. */
  useEffect(() => {
    if (mintError?.message) {
      setStatus(mintError.message)
      setStatusType('error')
      enqueueSnackbar(mintError.message, { variant: 'error' })
    }
  }, [mintError])

  const { handleSubmit } = useForm()

  const showForm = statusType != 'success'

  return (
    <div className="text-center">
      <p className="text-2xl text-white font-bold mb-5">
        <FontAwesomeIcon icon={solid('address-card')} /> Mochi NFT Mint - <small>{totalMintSupply}/50 minted</small>
      </p>

      <form onSubmit={handleSubmit(whitelistMint)}>
        <div className={totalMinted ? 'mb-0' : 'mb-0'}>
          <div className="flex place-content-center justify-self-center content-center self-center">
            <FlipCard>
              <FrontCard isCardFlipped={txSuccess}>
                <img
                  className="responsive"
                  src="/photo_2022-08-19_15-50-08.jpg"
                  width="100%"
                  height="450"
                  style={{ borderRadius: 8, height: '350px' }}
                />
              </FrontCard>
              <BackCard isCardFlipped={txSuccess}>
                <div style={{ padding: '14px', fontSize: '12px' }}>
                  <img src="/photo_2022-08-19_15-50-08.jpg" width="100%" height="100%" style={{ borderRadius: 8 }} />
                  <p style={{ marginBottom: 6 }}>
                    View on <a href={`https://www.teloscan.io/tx/${mintData?.hash}`}>Teloscan</a>
                  </p>
                  <p>
                    View on <a href={`https://byt.io/telos/collection/mochi`}>Byt Marketplace</a>
                  </p>
                </div>
              </BackCard>
            </FlipCard>
          </div>
        </div>

        <div>
          <div className="text-white mb-5 mt-4">
            {totalMinted > 0 && <p style={{ margin: '0px', color: 'green' }}>You have minted {totalMinted} NFTs.</p>}

            {status && showForm && statusType === 'error' && <p style={{ color: statusType == 'error' ? 'red' : 'green' }}>{status}</p>}

            <button
              style={{ marginTop: 5 }}
              className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none"
              data-mint-loading={isMintLoading}
              data-mint-started={isMintStarted}
              type="submit"
            >
              {status && statusType != 'error' && status}
              {isMintLoading && 'Waiting for approval'}
              {!isMintLoading && !isMintStarted && 'Mint NFT!'}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
})

export default RainbowKitMint
